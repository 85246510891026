import React from 'react';

import './contact-item.styles.scss';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react/lib/Styling';

const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
  });

const ContactItem = ({width, name, icon }) => (
    <div  style={{ width: width }}  className="contact-item">
            <div className='item'>
                <FontIcon iconName={icon} className={iconClass} />
                <div className='name'>{name}</div>
            </div>
    </div>
);

export default ContactItem; 