import React from 'react';

import './footer.styles.scss'

import { ReactComponent as Logo} from '../../assets/logo.svg';

import CompanyLogo from '../../assets/company.png'


const Footer = () => (
    <div id="footer" className='footer'>
        <Logo className="logo" />
        <img src={CompanyLogo} alt="company-logo" className="company-logo"  />
    </div>
);

export default Footer;