const INITIAL_STATE = {
    contactInformation: 'auto',
    locked: false,
}

const heightReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'SET_CONTACT_INFORMATION_HEIGHT':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}

export default heightReducer;