import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './incidents-confirmation.styles.scss';
import { useJsApiLoader } from '@react-google-maps/api';
import Map from '../../components/map/map.component';
import ContactInformation from '../../components/contact-information/contact-information.component';
import { signInAnonymously } from '../../firebase/firebase.utils';

import { getIncident, saveIncidentOnRead } from '../../utils/incident.utils';


function IncidentsConfirmation(props) {
    const params = useParams();
    const [items, setItems] = useState(null)
    const [dataIsReturned, setDataIsReturned] = useState(false)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyBK3Ad7RbAqzD0b5A_r2V2HeXGxxEUIM3Q',
      });

  useEffect(() => {
    const { tenant, id, collectionName } = params;
    signInAnonymously(tenant, id, collectionName).then(() => {
      saveIncidentOnRead(tenant, id, collectionName).then(() => {}).catch(() => {});
      getIncident(tenant, id, collectionName)
        .then((doc) => {
          const data = doc.data();
          setItems(data);
          setDataIsReturned(true);
        })
        .catch(() => {
            setDataIsReturned(true);

        });
    });
  }, []);

  return (
    <div className='incidents-confirmation'>
      <div className='map'>{isLoaded && items && <Map data={items} />}</div>
      <div id='main-card' className='contact-information'>
        {dataIsReturned ? (
          <ContactInformation
            route={{ tenant: params.tenant, id: params.id, collectionName: params.collectionName }}
            data={items}
          />
        ) : (
          <div>Loading</div>
        )}
      </div>
    </div>
  );
}

export default IncidentsConfirmation;
