const INITIAL_STATE = {
    address: '',
    position: {
        lat: null,
        lng: null
    }
}

const mapReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'SET_ADDRESS':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}

export default mapReducer;