/*global google*/
import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import store from '../../redux/store';
import { firestore } from '../../firebase/firebase.utils';
import { setAddress } from '../../redux/map/map.actions';
import { buildStyles } from 'react-circular-progressbar';
import './map.styles.scss';
import { Modal } from '@fluentui/react';
import destIcon from '../../assets/baseline_local_shipping_black_24dp.png';
import originIcon from '../../assets/baseline_account_circle_black_24dp.png';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import {
  convertDistance,
  convertDururation,
} from '../../helpers/value-converter.helpers';
const CustomMarker = () => <div className='marker'></div>;
const containerStyle = {
  width: '100%',
  height: '100%',
};
const iconClass = mergeStyles({
  fontSize: 50,
  height: 50,
  color: 'green',
  width: 50,
  margin: '0 25px',
});
function Map(props) {
  const [lock, setLock] = useState(false);
  const params = useParams();
  const [directions, setDirections] = useState(null);
  const [totalDuration, setTotalDuration] = useState(null);
  const [totalDistance, setTotalDistance] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [durationTxt, setDurationTxt] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [description, setDescription] = useState(null);
  const [status, setStatus] = useState(null);
  const [modalIsOpened, setModal] = useState(null);
  const [originMarker, setOriginMarker] = useState(null);
  const [destinationMarker, setDestinationMarker] = useState(null);
  const [center, setCenter] = useState({
    lat: 37.9684963,
    lng: 23.6971812,
  });
  const [position, setPosition] = useState({
    lat: 37.9684963,
    lng: 23.6971812,
  });

  useSelector((state) => {
    if (!lock && state.height.locked) {
      setLock(true);
    }
    if (
      document?.getElementById('main-card') &&
      document.getElementById('map')
    ) {
      const cardHeight = document.getElementById('main-card').clientHeight;
      document.getElementById(
        'map'
      ).style.height = `calc(${window.innerHeight}px - ${cardHeight}px - 64px)`;
    }
  });

  const mapOptions = {
    draggable: true,
    panControl: false,
    scaleControl: false,
    disableDoubleClickZoom: false,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControlOptions: false,
    mapTypeControl: false,
    zoomControl: false,
  };

  var change = false;

  const refMap = useRef(null);

  useEffect(() => {
    if (status === 'completed') {
      setModal(true);
      setPercentage(100);
      setTimeout(() => {
        setModal(false);
      }, 3000);
    }
  }, [status]);

  useEffect(() => {
    if (params?.id) {
      const docRef = firestore
        .collection(`/tenants/${params?.tenant}/data/${params?.collectionName}/items/`)
        .doc(params.id);
      docRef.onSnapshot((snapshot) => {
        const data = snapshot.data();
        setStatus(data?.status);
        if (data?.provider?.location?.lat && data?.provider?.location?.lng) {
          setDescription(data?.provider?.description);
          const newDestination = new google.maps.LatLng(
            data.provider.location.lat,
            data.provider.location.lng
          );
          // setPosition(new google.maps.LatLng(
          //   data.address.location.lat,
          //   data.address.location.lng
          // ))
          setDestinationMarker(newDestination);
          setDirections({
            destination: newDestination,
            origin: origin,
            travelMode: 'DRIVING',
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (props.confirmed) {
      setLock(true);
      if (props?.address?.location) {
        if (!change) {
          change = true;
        }
        setCenter(props.address.location);
      }
    }
  }, []);

  const onLoad = React.useCallback(function callback(map) {
    if (props?.data?.address?.location?.lat && props?.data?.address?.location?.lng) {
      setPosition(new google.maps.LatLng(props.data.address.location.lat, props.data.address.location.lng));
    }
    
    navigator.geolocation.getCurrentPosition((position) => {
      const { coords } = position;
      const { latitude, longitude } = coords;

      const pos = {
        lat: latitude,
        lng: longitude,
      }
      setCenter(pos);
      const newOrigin = new google.maps.LatLng(pos.lat, pos.lng);
      setOriginMarker(newOrigin);

      if (props?.data?.address?.location?.lat && props?.data?.address?.location?.lng) {
        setCenter(new google.maps.LatLng(props.data.address.location.lat, props.data.address.location.lng));
      } else {
        setCenter(pos);
      }
    });
  }, []);

  useEffect(() => {
    if (!directions || !originMarker || !destinationMarker) {
      return;
    }
    var newDirectionsService =
      directionsService ?? new google.maps.DirectionsService();
    setDirectionsService(newDirectionsService);
    var directionsRequest = {
      origin: originMarker,
      destination: destinationMarker,
      travelMode: google.maps.DirectionsTravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC,
    };
    newDirectionsService.route(
      directionsRequest,

      (response, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          if (directionsRenderer) {
            directionsRenderer.setMap(null);
          }
          const newDirectionsRenderer = new google.maps.DirectionsRenderer({
            map: refMap.current.state.map,
            directions: response,
            suppressMarkers: true,
          });
          setDirectionsRenderer(newDirectionsRenderer);
          const duration = response?.routes?.[0]?.legs?.[0]?.duration?.value;
          const distance = response?.routes?.[0]?.legs?.[0]?.distance?.value;
          if (!duration) {
            return;
          }
          setDurationTxt(convertDururation(duration));
          setTotalDistance(convertDistance(distance));
          if (!totalDuration) {
            setTotalDuration(duration);
            if (percentage !== 100) {
              setPercentage(0);
            }
          } else {
            if (percentage !== 100) {
              setPercentage(((totalDuration - duration) / totalDuration) * 100);
            }
          }
        } else {
          alert('Unable to retrive route');
        }
      }
    );
  }, [directions, originMarker, destinationMarker]);

  const handleBoundsChanged = () => {
    if (!refMap.current) return;
    const lat = refMap.current.state.map.center.lat();
    const lng = refMap.current.state.map.center.lng();
    const latlng = lat + ',' + lng;
    if (!lock) {
      setPosition({
        lat: lat,
        lng: lng,
      });
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=AIzaSyB6wxWuZeqQJJziDXIF4CwJqu3d-7chpYM`
      )
        .then((res) => res.json())
        .then((result) => {
          if (result.status !== 'REQUEST_DENIED') {
            let res = result.results;
            if (res.length >= 5) {
              res = res.splice(0, 5);
            }
            const addresses = res.map((x) => x.formatted_address);
            try {
              const address = addresses[0];
              store.dispatch(
                setAddress({
                  address: address,
                  position: {
                    lat: lat,
                    lng: lng,
                  },
                })
              );
            } catch {}
          }
        });
    }
  };

  return (
    <div id='map' style={{ width: '100%', height: '100%' }}>
      {directions && (
        <div
          style={{
            height: '160px',
            width: '100%',
            position: 'absolute',
            left: '50%',
            bottom: '0',
            transform: 'translate(-50%)',
            zIndex: 9988,
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                height: '80px',
                width: '44%',
                borderTopLeftRadius: '120px',
                borderTopRightRadius: '120px',
                background: 'white',
              }}
            ></div>
            <div
              style={{
                height: '80px',
                width: '100%',
                background: 'white',
                position: 'relative',
              }}
            >
              <div className='distance'>{totalDistance}</div>
              {status && (
                <div
                  className={`chip ${
                    status === 'completed' ? 'chip-success' : 'chip-started'
                  }`}
                >
                  {status}
                </div>
              )}
            </div>
            <div
              style={{
                position: 'absolute',
                top: '14px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className='description'>{description}</div>
              <CircularProgressbarWithChildren
                value={percentage}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: 'round',

                  // Text size
                  textSize: '12px',

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: `rgba(62, 152, 199, ${66 / 100})`,
                  textColor: '#f88',
                  trailColor: '#d6d6d6',
                  backgroundColor: '#3e98c7',
                })}
              >
                <div
                  className='center'
                  style={{
                    marginTop: '-4px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div>{durationTxt}</div>
                </div>
              </CircularProgressbarWithChildren>
            </div>
          </div>
        </div>
      )}
      {modalIsOpened && (
        <Modal isOpen={true}>
          <div
            style={{
              width: '100%',
              height: '180px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FontIcon
              aria-label='Completed'
              iconName='Completed'
              className={iconClass}
            />
            <div style={{ marginTop: '13px' }}>Η αποστολή έχει φτάσει</div>
          </div>
        </Modal>
      )}

      <GoogleMap
        onLoad={onLoad}
        ref={refMap}
        options={mapOptions}
        mapContainerStyle={containerStyle}
        center={center}
        defaultZoom={16}
        zoom={16}
        onDragEnd={handleBoundsChanged}
      >
        {originMarker && destinationMarker && (
          <div>
            <Marker position={originMarker} icon={originIcon} />
            <Marker position={destinationMarker} icon={destIcon} />
          </div>
        )}

        {!lock && <CustomMarker />}
        {lock && !directions && <Marker position={position} />}
      </GoogleMap>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    height: state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setAddress: (position) => dispatch(setAddress(position)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Map);
