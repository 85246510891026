import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
// the hoc
import { initializeIcons } from '@uifabric/icons';
import IncidentsConfirmation from './pages/incidents-confirmation/incidents-confirmation.component';
import Footer from './components/footer/footer.component';
import React from 'react';

function App() {
  initializeIcons();

  return (
    <Router>
      <Switch>
        <Route exact path='/:tenant/:collectionName/:id' component={IncidentsConfirmation} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default connect()(App);
