export const convertDururation = (duration) => {
    let hours =
      Math.floor(duration / 3600) >= 1 ? `${Math.floor(duration / 3600)}` : '';
    let minutes =
      Math.round((duration / 60) % 60) >= 1
        ? `${Math.round((duration / 60) % 60)}`
        : '';
    let finalText = '';
    hours = hours
      .split('')
      .map((x) => {
        if (x === '.') {
          return ',';
        }
        if (x === ',') {
          return '.';
        }
        return x;
      })
      .join('');
    minutes = minutes
      .split('')
      .map((x) => {
        if (x === '.') {
          return ',';
        }
        if (x === ',') {
          return '.';
        }
        return x;
      })
      .join('');
    if (hours !== '') {
      finalText = `${hours} ώρες`;
    }
    if (minutes !== '' && hours !== '') {
      finalText = `${finalText} ${minutes} λεπτά`;
    }
    if (minutes !== '' && hours === '') {
      finalText = `${minutes} λεπτά`;
    }
    return finalText;
  }

  export const convertDistance = (distance) => {
    const hours =
      Math.floor(distance / 1000) >= 1 ? `${Math.floor(distance / 1000)}` : '';
    const minutes =
      Math.floor(distance % 1000) >= 1 ? `${Math.floor(distance % 1000)}` : '';
    let finalText = '';
    if (hours !== '') {
      const meters = (distance / 1000).toString().split('.');
      try {
        if (meters[1]) {
          try {
            let first = Math.round(+meters[1])
              .toString()
              .split('')[0];
            let second = Math.round(+meters[1])
              .toString()
              .split('')[1];
            let val = Math.round(+`${first}.${second}`).toString();
            finalText = `${hours},${val} χλμ`;
          } catch {
            finalText = `${hours},${meters[1].split('')[0]} χλμ`;
          }
        } else {
          finalText = `${hours} χλμ`;
        }
      } catch {
        finalText = `${hours} χλμ`;
      }
    }
    if (minutes !== '' && hours === '') {
      finalText = `${minutes} μέτρα`;
    }
    return finalText;
  }