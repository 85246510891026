import React, { Component } from "react";
import { connect } from "react-redux";

import "./contact-information.styles.scss";

import ContactItem from "../contact-item/contact-item.component";

import { compose } from "recompose";

import { MessageBar, MessageBarType } from "@fluentui/react/lib/";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { confirmPosition } from "../../utils/incident.utils";
import { withTranslation } from "react-i18next";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/";

import i18n from '../../i18n';

import {
  DocumentCard,
  DocumentCardType,
  DocumentCardDetails,
} from "@fluentui/react/lib/DocumentCard";
import { Stack } from "@fluentui/react/lib/Stack";

import { setContactInformationHeight } from "../../redux/height/height.actions";

import store from "../../redux/store";

const stackTokens = { childrenGap: 20 };

const dialogStyles = { main: { maxWidth: 450 } };


const SuccessExample = ({ message }) => (
  <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
    {message}
  </MessageBar>
);

class ContactInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmed: false,
      showMessageBar: false,
      position: {
        lat: null,
        lng: null,
      },
      dialog: {
        hideDialog: true,
        toggleHideDialog: true,
        modalProps: {
          isBlocking: false,
          styles: dialogStyles,
          dragOptions: undefined,
        }
      },
      items: [
        {
          id: 0,
          name: "Info",
          icon: "Message",
          width: "100%",
        },
        {
          id: 1,
          name: "Address",
          icon: "CompassNW",
          width: "100%",
        },
        {
          id: 2,
          name: "Full Name",
          icon: "ContactInfo",
          width: "100%",
        },
        {
          id: 3,
          name: "Vehicle Plate",
          icon: "Car",
          width: "50%",
        },
        {
          id: 4,
          name: "Mobile",
          icon: "CellPhone",
          width: "50%",
        },
      ],
    };
  }

  confirm() {
    this.setState({
      dialog: {
        hideDialog: false,
      },
    });
  }

  componentDidMount() {
    const { items } = this.state;
    const { data } = this.props;

    const action = new URLSearchParams(window.location.search).get("action");

    if (action !== "confirmation") {
      this.setState({
        confirmed: true,
      });
    }

    if (data) {
      let name = "";
      let plate = "";
      let phoneNumber = "";
      let address = "";

      if (data.confirmed) {
        this.setState({
          confirmed: true,
          showMessageBar: true,
        });
      }

      if (data?.driver?.lastname || data?.insured?.lastname) {
        name = data?.driver?.lastname || data?.insured?.lastname;
      }

      if (data?.driver?.firstname || data?.insured?.firstname) {
        name = name + " " + (data?.driver?.firstname || data?.insured?.firstname);
      }

      if (data?.vehicle?.plate) {
        plate = data?.vehicle?.plate;
      }

      if (data?.driver?.mobile1 || data?.insured?.mobile1) {
        phoneNumber = (data?.driver?.mobile1 || data?.insured?.mobile1);
      }

      if (data?.serviceRequest?.address?.description) {
        address = data?.serviceRequest?.address?.description;
      }

      items[0].name = i18n.t("Info");
      items[1].name = address;
      items[2].name = name;
      items[3].name = plate;
      items[4].name = phoneNumber;

      this.setState({
        items: items,
      });
    }

    setTimeout(() => {
      const height =
      document.getElementById("contact-information").clientHeight + 20;
      this.setState({ height });
      store.dispatch(setContactInformationHeight({
        height: height,
        locked: data.confirmed
      }));
    });

    store.subscribe(() => {
      const state = store.getState();

      if (!this.state.confirmed) {
        const address = state.map.address;
        const items = this.state.items;

        items[1].name = address;

        this.setState({
          items: items,
          position: state.map.position,
        });
      }
    });
  }

  hideDialog(e){
    this.setState({
      dialog: {
        hideDialog: true
      }
    })
    const { tenant, id, collectionName } = this.props.route;
    if(e === 'send'){
      confirmPosition(
        tenant,
        id,
        this.state.position,
        this.state.items[1].name,
        collectionName
      )
        .then(() => {})
        .finally(() => {
          const height = document.getElementById("main-card").clientHeight;
          this.setState({
            height,
            confirmed: true,
            showMessageBar: true
          });
          store.dispatch(setContactInformationHeight({
            height: height,
            locked: true
          }));
        });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div id="contact-information" className="contact-information">
        <Dialog
          hidden={this.state.dialog.hideDialog}
          onDismiss={() => this.hideDialog('dont-send')}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('Title'),
            closeButtonAriaLabel: "Close",
            subText: t('SubText')
          }}
          modalProps={this.state.dialog.modalProps}
        >
          <DialogFooter>
            <PrimaryButton onClick={() => this.hideDialog('send')} text={t('Yes')} />
            <DefaultButton onClick={() => this.hideDialog('dont-send')} text={t('No')} />
          </DialogFooter>
        </Dialog>
        {this.state.showMessageBar ? (
          <SuccessExample message={t("Message")} className="success-dialog" />
        ) : (
          <div />
        )}
        {this.state.confirmed ? (
          <div />
        ) : (
          <div style={{ margin: "10px auto 0" }}>
            <PrimaryButton
              onClick={() => {
                this.confirm();
              }}
              className="submit"
              disabled={this.state.position.lat===null}
              text={t("Submit")}
            />
          </div>
        )}
        <Stack className="custom-card" tokens={stackTokens}>
          <DocumentCard
            style={{ width: "100%", height: "100%" }}
            type={DocumentCardType.compact}
          >
            <DocumentCardDetails>
              <div className="items">
                {this.state.items.map((item) => {
                  return (
                    <ContactItem
                      name={item.name}
                      icon={item.icon}
                      width={item.width}
                      key={item.id}
                    />
                  );
                })}
              </div>
            </DocumentCardDetails>
          </DocumentCard>
        </Stack>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    map: state.map,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setContactInformationHeight: (height) =>
    dispatch(setContactInformationHeight(height)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(ContactInformation);
