import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "Title": "Confirm",
      "SubText": "Are you sure this is your location?",
      "Info":"Please drag the map and set the pin in your current location",
      "Yes": "Yes",
      "No": "No",
      "Submit": "CONFIRM LOCATION",
      "Message": "You have successfully confirmed your location"
    }
  },
  el: {
    translation: {
      "Title": "Επιβεβαίωση",
      "SubText": "Είστε σίγουρος ότι αυτή είναι η τοποθεσία σας;",
      "Info":"Παρακαλούμε σύρετε τον χάρτη και δηλώστε την τρέχουσα τοποθεσία σας με την πινέζα",
      "Yes": "Ναι",
      "No": "Όχι",
      "Submit": "ΕΠΙΒΕΒΑΙΩΣΗ ΤΟΠΟΘΕΣΙΑΣ",
      "Message": "Η τοποθεσία σας, καταχωρήθηκε με επιτυχία"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: (window.navigator.userLanguage || window.navigator.language) === 'el-GR' ? "el" : "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;