import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/analytics';

const config = {
    apiKey: "AIzaSyDbWprPu0hYjR0Z1XN00Eau1-laEi0BBPs",
    authDomain: "upgradegr-shareloc.firebaseapp.com",
    databaseURL: "https://upgradegr-shareloc-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "upgradegr-shareloc",
    storageBucket: "upgradegr-shareloc.appspot.com",
    messagingSenderId: "809962225555",
    appId: "1:809962225555:web:38182cd873de2396ba7266",
    measurementId: "G-YJGC6FXHFB"
  };

  const firebaseapp = firebase.initializeApp(config);

  export const auth = firebase.auth();
  export const firestore = firebase.firestore();
  firebaseapp.analytics();

  export const signInAnonymously = (tenant, id, collectionName) => {
    return firebase.auth().signInAnonymously().then(() => {
        return firebase.auth().onAuthStateChanged((user) => {
          if(user){
            localStorage.setItem('user', JSON.stringify(user));
            return firestore
              .collection(`/tenants/${tenant}/data/${collectionName}/items/`)
              .doc(id)
              .set(
                {
                  uid: user.uid,
                  auth: {
                    refreshToken: user.refreshToken
                  }
                },
                { merge: true }
              )
          }
        })
    })
  }

  export default firebase;