import { firestore } from '../firebase/firebase.utils';

export const getIncident = (tenant, id, collectionName) => {
  return firestore
    .collection(`/tenants/${tenant}/data/${collectionName}/items/`)
    .doc(id)
    .get();
};

export const saveIncidentOnRead = async (tenant, id, collectionName) => {
  await firestore
    .collection(`/tenants/${tenant}/data/${collectionName}/items/`)
    .doc(id)
    .get()
    .then((val) => {
      const data = val.data();
      const readAt = data.readAt;

      if (!readAt) {
        firestore
          .collection(`/tenants/${tenant}/data/${collectionName}/items/`)
          .doc(id)
          .set(
            {
              readAt: new Date().toISOString(),
              readBy: JSON.parse(localStorage.getItem('user')).uid,
            },
            { merge: true }
          );
      }
    })
    .then(async () => {
      firestore
        .collection(`/tenants/${tenant}/data/${collectionName}/items/${id}/events`)
        .add(
          {
            uid: JSON.parse(localStorage.getItem('user')).uid,
            readAt: new Date().toISOString(),
            action: 'read',
            browser: detectBrowser(),
            // ip: await fetch('http://api.ipify.org/?format=json')
            //   .catch(() => {})
            //   .then((res) => res.json())
            //   .then((res) => res.ip),
            os: os(),
          },
          { merge: true }
        );
    });
};

export const saveIncidentOnUpdate = async (tenant, id, collectionName) => {
  return firestore
    .collection(`/tenants/${tenant}/data/${collectionName}/items/${id}/events`)
    .add(
      {
        uid: JSON.parse(localStorage.getItem('user')).uid,
        confirmedAt: new Date().toISOString(),
        action: 'confirm',
        browser: detectBrowser(),
        // ip: await fetch('http://api.ipify.org/?format=json')
        //   .then((res) => res.json())
        //   .then((res) => res.ip),
        os: os(),
      },
      { merge: true }
    );
};

export const confirmPosition = (tenant, id, position, address, collectionName) => {
  return firestore
    .collection(`/tenants/${tenant}/data/${collectionName}/items/`)
    .doc(id)
    .set(
      {
        confirmed: true,
        confirmedAt: new Date().toISOString(),
        address: {
          location: position,
          description: address,
        },
      },
      { merge: true }
    )
    .then(() => {
      saveIncidentOnUpdate(tenant, id, collectionName);
    });
};

export const os = () => {
  switch (true) {
    case navigator.appVersion.indexOf('Win') !== -1:
      return 'Windows OS';
    case navigator.appVersion.indexOf('Mac') !== -1:
      return 'MacOS';
    case navigator.appVersion.indexOf('X11') !== -1:
      return 'UNIX OS';
    case navigator.appVersion.indexOf('Linux') !== -1:
      return 'Linux OS';
    default:
      return 'other';
  }
};

export const detectBrowser = () => {
  const agent = window.navigator.userAgent.toLowerCase();
  switch (true) {
    case agent.indexOf('edge') > -1:
      return 'edge';
    case agent.indexOf('opr') > -1 && !!window.opr:
      return 'opera';
    case agent.indexOf('chrome') > -1 && !!window.chrome:
      return 'chrome';
    case agent.indexOf('trident') > -1:
      return 'ie';
    case agent.indexOf('firefox') > -1:
      return 'firefox';
    case agent.indexOf('safari') > -1:
      return 'safari';
    default:
      return 'other';
  }
};
